import React from 'react';
import Layout from '../components/Layout';
import EmailCaptureForm from '../components/EmailCaptureForm';
import {
  Row,
  Column,
  Container,
  Heading,
  Text,
  Measure,
  Button,
  Image,
  Card,
  Subhead,
  Divider,
  Fixed,
  Modal,
} from 'rebass/emotion';
import { css } from 'react-emotion';
import theme from '../theme';
import style from '../utils/style';
import csv from '../assets/csv.svg';
import xlsx from '../assets/xlsx.svg';
import connectorLines from '../assets/derive-insight-connectors.svg';
import db from '../assets/db.svg';
import sheet from '../assets/sheet-icon.svg';
import tableIcon from '../assets/table-icon.svg';
import notebookIcon from '../assets/notebook-icon.svg';
import { FaUpload, FaLongArrowAltRight } from 'react-icons/fa';
import { MdTimer, MdCheckCircle } from 'react-icons/md';

const buttonStyle = css({
  fontSize: '20px',
  textTransform: 'capitalize',
  transition: 'all 0.1s',
  cursor: 'pointer',
  borderRadius: '4px',
  boxShadow: style.shadows.low,
  '&:focus': {
    boxShadow: style.shadows.low,
  },
  '&:hover': {
    transform: `translateY(-2px) scale(1.01, 1.01)`,
    boxShadow: style.shadows.high,
  },
  '&:active': {
    boxShadow: style.shadows.low,
    transform: `translateY(0) scale(1.0, 1.0)`,
    transition: `transform 50ms`,
  },
});

const changeOrientation = css`
  display: flex;
  width: 100%;
  ${style.mediaQueries.maxTablet} {
    && {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

const toggleDisplay = css`
  display: none;
  ${style.mediaQueries.maxTablet} {
    && {
      display: flex;
    }
  }
`;

const mobileHeight = css`
  height: 130px;
  ${style.mediaQueries.maxTablet} {
    && {
      height: 80px;
    }
  }
`;

class IndexPage extends React.Component {
  state = { clicked: false };

  toggleModal = e => {
    this.setState({ status: '', clicked: !this.state.clicked });
  };

  render() {
    const { clicked } = this.state;
    return (
      <Layout location={this.props.location} toggleModal={this.toggleModal}>
        {clicked && (
          <div>
            <Fixed
              css={{ opacity: 0.4 }}
              bg={'black'}
              top={0}
              right={0}
              bottom={0}
              left={0}
              onClick={this.toggleModal}
            />
            <Modal bg={theme.colors.grayScale[0]} width={512}>
              <Text fontSize={4} />
              <EmailCaptureForm />
            </Modal>
          </div>
        )}
        <Row mt={[0, 6]} mx={0}>
          <Column css={{ label: 'outside-column', maxWidth: '1200px', margin: '0 auto' }} mb={0}>
            <Row mt={[0, 3]} css={{ flexWrap: 'wrap' }}>
              <div className={changeOrientation}>
                <Column mt={[0, 4]} w={[1, 1 / 2]} css={{ label: 'inside-column' }}>
                  <Container mx={-16} px={[32]}>
                    <Heading
                      fontSize={[5, 6]}
                      color={theme.colors.grayScale[6]}
                      css={{ fontFamily: theme.fonts.display, fontWeight: 400 }}>
                      Streamline Your Discovery Infrastructure
                    </Heading>
                  </Container>
                  <Container mx={-16} px={32} pt={4} maxWidth={500}>
                    <Measure color={theme.colors.grayScale[5]} fontSize={3}>
                      Organizational data has a habit of becoming siloed. This makes analysis between silos
                      difficult and time-consuming.
                    </Measure>
                    <Measure color={theme.colors.grayScale[5]} pt={3} fontSize={3}>
                      Scopana gives you the tools to surface data into actionable insight.
                    </Measure>
                  </Container>
                  <Container mt={4} w={[2 / 5, 1 / 2]}>
                    <Button
                      children="Try Now"
                      py={3}
                      fontSize={4}
                      bg={theme.colors.oranges[1]}
                      className={buttonStyle}
                      onClick={this.toggleModal}
                    />
                  </Container>
                </Column>
                <Column w={[1, 1 / 2]}>
                  <Row mt={4} css={{ justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                    <Image w={[1 / 7, 'initial']} mb={3} src={csv} />
                    <Image w={[1 / 7, 'initial']} mb={3} src={xlsx} />
                    <Image w={[1 / 7, 'initial']} mb={3} src={db} />
                  </Row>
                  <Row css={{ justifyContent: 'center' }}>
                    <Image className={mobileHeight} py={0} mb={3} pl={3} src={connectorLines} />
                  </Row>
                  <Row css={{ justifyContent: 'space-evenly' }}>
                    <Image w={[1 / 7, 'initial']} mb={0} src={tableIcon} />
                    <Image w={[1 / 7, 'initial']} mb={0} src={notebookIcon} />
                  </Row>
                </Column>
              </div>
            </Row>
            <Divider
              className={toggleDisplay}
              borderBottom={2}
              borderColor={style.customColors.blueGrayFade[1]}
            />
            <Row mt={[5, 6]} css={{ flexWrap: 'wrap' }}>
              <div className={changeOrientation}>
                <Column w={[1, 1 / 2]}>
                  <Row mt={1} css={{ justifyContent: 'space-evenly' }}>
                    <Card
                      css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      bg={'#e1eaed'}
                      ml={[0, -5]}
                      px={[4, 6]}
                      py={5}>
                      <FaUpload
                        size={52}
                        color={theme.colors.grayScale[8]}
                        style={{ verticalAlign: 'sub', opacity: 0.8, marginLeft: '6px' }}
                      />
                      <Subhead color={theme.colors.grayScale[6]} mt={3} children="Drag and Drop Data..." />
                    </Card>
                  </Row>
                </Column>
                <Column w={[1, 1 / 2]}>
                  <Container mx={-16} px={32}>
                    <Heading
                      fontSize={[5, 6]}
                      color={theme.colors.grayScale[6]}
                      css={{ fontFamily: theme.fonts.display, fontWeight: 400 }}>
                      Import From Anywhere
                    </Heading>
                  </Container>
                  <Container mx={-16} px={32} pt={4} maxWidth={500}>
                    <Measure fontSize={3} color={theme.colors.grayScale[5]}>
                      Data shouldn’t be held hostage. Scopana gives you the power to import Google Sheets,
                      databases, APIs, and other sources directly into the browser session for fast and easy
                      analysis.
                    </Measure>
                  </Container>
                </Column>
              </div>
            </Row>
            <Divider
              className={toggleDisplay}
              borderBottom={2}
              borderColor={style.customColors.blueGrayFade[1]}
            />
            <Row mt={[5, 6]} css={{ flexWrap: 'wrap' }}>
              <Column w={[1, 1 / 2]}>
                <Container mx={-16} px={32}>
                  <Heading
                    fontSize={[5, 6]}
                    color={theme.colors.grayScale[6]}
                    css={{ fontFamily: theme.fonts.display, fontWeight: 400 }}>
                    Immediate Query Feedback
                  </Heading>
                </Container>
                <Container mx={-16} px={32} pt={4} maxWidth={500}>
                  <Measure color={theme.colors.grayScale[5]} fontSize={3}>
                    Query your data using familiar interfaces.
                  </Measure>
                  <Measure
                    bg={theme.colors.grayScale[0]}
                    color={theme.colors.grayScale[6]}
                    mt={3}
                    pl={3}
                    py={3}
                    fontSize={2}
                    fontFamily={theme.fonts.mono}>
                    SELECT first_name, last_name FROM users WHERE age > 25
                  </Measure>
                </Container>
              </Column>
              <Column w={[1, 1 / 2]}>
                <Row mx={-16} px={32} mt={4} css={{ justifyContent: 'space-evenly' }}>
                  <Image src={sheet} />
                </Row>
              </Column>
            </Row>
            <Divider
              className={toggleDisplay}
              borderBottom={2}
              borderColor={style.customColors.blueGrayFade[1]}
            />
            <Row mt={[5, 6]} css={{ flexWrap: 'wrap' }}>
              <div className={changeOrientation}>
                <Column w={[1, 1 / 2]}>
                  <Row mx={-16} px={32} mt={4} css={{ label: 'card-row', justifyContent: 'center' }}>
                    <Card
                      css={{
                        width: '100%',
                        label: 'card',
                        zIndex: '-2',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                      bg={'#e1eaed'}
                      py={4}>
                      <MdTimer
                        size={75}
                        color={theme.colors.oranges[2]}
                        style={{ zIndex: '-1', verticalAlign: 'sub', opacity: 0.8 }}
                      />
                      <FaLongArrowAltRight
                        size={75}
                        color={theme.colors.grayScale[7]}
                        style={{ zIndex: '-1', verticalAlign: 'sub', opacity: 0.8, marginLeft: '6px' }}
                      />
                      <MdCheckCircle
                        size={75}
                        color={theme.colors.greens[0]}
                        style={{ zIndex: '-1', verticalAlign: 'sub', opacity: 0.8, marginLeft: '6px' }}
                      />
                    </Card>
                  </Row>
                </Column>
                <Column w={[1, 1 / 2]}>
                  <Container mx={-16} px={32}>
                    <Heading
                      fontSize={[5, 6]}
                      color={theme.colors.grayScale[6]}
                      css={{ fontFamily: theme.fonts.display, fontWeight: 400 }}>
                      Schedule Jobs
                    </Heading>
                  </Container>
                  <Container mx={-16} px={32} pt={4} maxWidth={500}>
                    <Measure fontSize={3} color={theme.colors.grayScale[5]}>
                      After you have finished analyzing your data, Scopana lets you schedule recurring derived
                      analysis from data sources. Data engineering at your finger tips.
                    </Measure>
                  </Container>
                </Column>
              </div>
            </Row>
            <Divider borderBottom={2} borderColor={style.customColors.blueGrayFade[1]} />
            <Row my={6} css={{ display: 'flex', flexWrap: 'wrap' }}>
              <Column w={[1, 1 / 2]}>
                <Heading
                  fontSize={[5, 6]}
                  ml={['54px', 5]}
                  color={theme.colors.grayScale[6]}
                  css={{ fontFamily: theme.fonts.display, fontWeight: 400 }}>
                  Get Started Today
                </Heading>
              </Column>
              <Column w={[1, 1 / 2]}>
                <Container css={{ marginLeft: '115px' }} mt={0} w={1 / 2}>
                  <Button
                    children="Try Now"
                    py={3}
                    fontSize={4}
                    bg={theme.colors.oranges[1]}
                    className={buttonStyle}
                    onClick={this.toggleModal}
                  />
                </Container>
              </Column>
            </Row>
          </Column>
        </Row>
      </Layout>
    );
  }
}

export default IndexPage;
